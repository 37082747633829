import { useEffect, useState } from "react";
import { useAppSelector } from "../app/hooks";
import { CONTENT_TYPE, ContentType, IHTTPBodyTreeNode, ReadonlyHTTPMessageProps } from "../test-studio/models";
import { processMessage } from "../util/MessageUtils";
import { isEmpty } from "lodash";

const useReadOnlyHTTPMessage = ({ message, isResponse }: ReadonlyHTTPMessageProps) => {
  const [bodyString, setBodyString] = useState<string>("");
  const [bodyTree, setBodyTree] = useState<IHTTPBodyTreeNode[]>([]);
  const loading = useAppSelector((state) => state.tests.getRequestLoading);
  const [contentType, setContentType] = useState(ContentType.TEXT);

  useEffect(() => {
    if (message) {
      const processedMessage = processMessage(message);

      console.log("====Readonly Message====");
      console.log("message : ", message);

      setBodyTree(processedMessage.bodyTree);
      setBodyString(processedMessage.bodyString);
    }
  }, [message]);

  useEffect(() => {
    let matchingType;
    const incommingType = message?.headers?.[CONTENT_TYPE];
    if (incommingType) {
      matchingType = Object.values(ContentType).find(
        (obj) => (incommingType as string).includes(obj.value) // TODO - need to handle charset as well.
      );
    } else {
      // If content type is not specified in the message headers or the generators,
      // use the filled message body field to figure out the content type.
      if (message?.body?.jsonBody) {
        matchingType = ContentType.JSON;
      } else if (message?.body?.xmlBody) {
        matchingType = ContentType.XML;
      } else if (message?.body?.httpFormDataBody) {
        matchingType = ContentType.FORM_URL_ENCODED;
      } else if (message?.body?.httpFormDataBody) {
        matchingType = ContentType.MULTIPART_FORM;
      }
    }

    setContentType(matchingType ?? ContentType.TEXT);
  }, [message, message?.headers?.[CONTENT_TYPE]]);

  return {
    loading,
    method: message && "httpMethod" in message ? message.httpMethod : undefined,
    body: {
      string: bodyString,
      setString: setBodyString,
      contentType,
      isReadOnly: true,
    },
    tree: {
      body: bodyTree,
      setBody: setBodyTree,
      expandAll: !isResponse,
      expandParent: isResponse,
    },
    generators: {
      enabled: false,
      body: {},
      header: {},
      query: {},
      path: {},
    },
    availableVariables: [],
    availableParameters:[],
  };
};

export default useReadOnlyHTTPMessage;
