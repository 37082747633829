import React, { useEffect, useState } from "react";
import { Button, Flex, Space, Spin, StepProps, Steps, theme } from "antd";
import GeneralStep from "./GeneralStep";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeGenerationPopup, generateApiTests, updateCurrentStep } from "../../features/testGenerationSlice";
import PreSteps from "./PreSteps";
import { GenerationStep, IFieldUpdateRule, PreStep } from "../models";
import SuccessStep from "./SuccessStep";
import ErrorStep from "./ErrorStep";

const GenerationSteps: React.FC = () => {
  const dispatch = useAppDispatch();
  const { token } = theme.useToken();

  const stepsMapping = {
    [GenerationStep.GENERAL]: <GeneralStep />,
    [GenerationStep.PRE_STEPS]: <PreSteps />,
    [GenerationStep.CUSTOM_RULES]: <GeneralStep />,
    [GenerationStep.SUCCESS]: <SuccessStep />,
    [GenerationStep.ERROR]: <ErrorStep />,
  };

  const [items, setItems] = useState<StepProps[]>([]);
  const operationId = useAppSelector((state) => state.testGeneration.operationId);
  const generationSteps = useAppSelector((state) => state.testGeneration.generationSteps);
  const currentStep = useAppSelector((state) => state.testGeneration.currentStep);
  const selectedCategories = useAppSelector((state) => state.testGeneration.selectedCategories);
  const additionalPrompt = useAppSelector((state) => state.testGeneration.additionalPrompt);
  const testSuite = useAppSelector((state) => state.testGeneration.testSuite);
  const preStepsType = useAppSelector((state) => state.testGeneration.preStepsType);
  const preTestId = useAppSelector((state) => state.testGeneration.preTestId);
  const fieldUpdateRules = useAppSelector((state) => state.testGeneration.fieldUpdateRules);
  const generateTestsLoading = useAppSelector((state) => state.testGeneration.generateTestsLoading);

  useEffect(() => {
    const items = generationSteps.map((step, index) => {
      return { key: step, title: step, disabled: isStepDisabled(step), status: getStepStatus(index, step) };
    });
    setItems(items);
  }, [generationSteps, testSuite, currentStep]);

  const isStepDisabled = (step: GenerationStep) => {
    return step == GenerationStep.PRE_STEPS ? !testSuite : false;
    //TODO : Can update with other cases when more steps are added
  };

  const getStepStatus = (index: number, step: GenerationStep): StepProps["status"] => {
    if (index < currentStep) {
      return "finish";
    } else if (index == currentStep) {
      if (step == GenerationStep.SUCCESS) {
        return "finish";
      } else if (step == GenerationStep.ERROR) {
        return "error";
      } else {
        return "process";
      }
    } else {
      return "wait";
    }
  };

  const onChange = (value: number) => {
    dispatch(updateCurrentStep(value));
  };

  const next = () => {
    dispatch(updateCurrentStep(currentStep + 1));
  };

  const prev = () => {
    dispatch(updateCurrentStep(currentStep - 1));
  };

  const onGenerate = async () => {
    dispatch(
      generateApiTests({
        operationId: operationId!,
        config: {
          customPrompt: additionalPrompt,
          generateCategories: selectedCategories,
          testSuiteName: testSuite!,
          customRulePrompts: [],
          // Id of the test to run for setting up the context for the API test.
          prerunTestId: preTestId,
          fieldUpdateRules: fieldUpdateRules
            .filter((rule) => rule.isEnable)
            .map(
              (rule) =>
                ({ section: rule.section, path: rule.path, replaceConfig: rule.replaceConfig }) as IFieldUpdateRule
            ),
        },
      })
    );
  };

  const contentStyle: React.CSSProperties = {
    height: 400,
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    paddingLeft: 0,
    paddingRight: 0,
  };

  const boxStyle: React.CSSProperties = {
    width: "100%",
    marginTop: 20,
  };

  return (
    <>
      <Steps style={{marginTop:"20px"}}current={currentStep} onChange={onChange} items={items} />
      <Spin spinning={generateTestsLoading}>
        <Flex style={contentStyle}>{stepsMapping[generationSteps[currentStep]]}</Flex>
      </Spin>
      {![GenerationStep.SUCCESS, GenerationStep.ERROR].includes(generationSteps[currentStep]) && (
        <Flex style={boxStyle} justify={"space-between"} align={"baseline"}>
          <Space>
            <Button disabled={currentStep <= 0} onClick={prev}>
              Previous
            </Button>
            <Button
              type="primary"
              disabled={currentStep >= generationSteps.length - 1 || testSuite == undefined}
              onClick={next}
            >
              Next
            </Button>
          </Space>
          <Space>
            <Button
              type="primary"
              disabled={testSuite == undefined || (preStepsType == PreStep.UPTO && !preTestId)}
              onClick={onGenerate}
            >
              Generate
            </Button>

            <Button onClick={() => dispatch(closeGenerationPopup())}>Cancel</Button>
          </Space>
        </Flex>
      )}
    </>
  );
};

export default GenerationSteps;
