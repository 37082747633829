import { Box } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import {
  GeneratorType,
  HTTPMessageSection,
  IGenerator,
  IVariable,
} from "../models";
import { Mentions } from "antd";
import { MentionsRef } from "antd/es/mentions";
import { useAppDispatch } from "../../app/hooks";
import {
  addVariableToGenerator,
  setGenerator,
} from "../../features/testsSlice";
import { getGeneratorLabel } from "../../util/MessageUtils";

interface GeneratorTextFieldProps {
  fieldKey: string;
  section: HTTPMessageSection;
  generator: IGenerator;
  variables: IVariable[];
  parameters: String[];
}

const GeneratorTextField: React.FC<GeneratorTextFieldProps> = ({
  fieldKey,
  section,
  generator,
  variables,
  parameters,
}) => {
  const dispatch = useAppDispatch();

  const mentionsRef = useRef<MentionsRef>(null);

  const [data, setData] = useState({
    value: "",
    isDisabled: false,
  });

  const onDrop = (item: IVariable) => {
    !data.isDisabled &&
      dispatch(
        addVariableToGenerator({
          section: section,
          key: fieldKey,
          variable: item,
        })
      );
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "variable",
    drop: onDrop,
    canDrop: () => !data.isDisabled,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  if (isActive && mentionsRef.current && !data.isDisabled) {
    mentionsRef.current.focus();
  }

  useEffect(() => {
    switch (generator?.type) {
      case GeneratorType.FREEFORM:
        setData({
          value: getGeneratorLabel(generator),
          isDisabled: false,
        });
        break;
      case GeneratorType.VOCABULORY:
        setData({
          value: getGeneratorLabel(generator),
          isDisabled: true,
        });
        break;
      case GeneratorType.SCRIPT:
        setData({
          value: getGeneratorLabel(generator),
          isDisabled: true,
        });
        break;
      default:
        break;
    }
  }, [generator]);

  return (
    <Box ref={drop} flexGrow={1}>
      <Mentions
        ref={mentionsRef}
        allowClear
        autoFocus={isActive}
        prefix={"{{"}
        value={data.value}
        disabled={data.isDisabled}
        readOnly={data.isDisabled}
        onChange={(text: string) => {
          // let value: any = text;   //TODO : Type realted implementation is to be done with ant mentions/ textfield

          // switch (typeof defaultValue) {
          //   case "number":
          //     value = Converters.stringToFloat(value);
          //     break;
          //   case "boolean":
          //     value = Converters.stringToBool(value);
          //     break;
          //   default:
          //     value = value;
          //     break;
          // }

          dispatch(
            setGenerator({
              key: fieldKey,
              section,
              generator: {
                type: GeneratorType.FREEFORM,
                value: text,
                displayValue: text,
              },
            })
          );
        }}
        options={[
          ...(variables?.map((variable) => ({
            value: `${variable.name}}}`,
            label: variable.name,
          })) || []),
          ...(parameters?.map((param) => ({
            value: `${param}}}`,  // Assuming the same format is needed
            label: param,
          })) || []),
        ]}
      />
    </Box>
  );
};

export default memo(GeneratorTextField);
