import { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Typography,
} from "@mui/material";
import {
  AccountTree,
  Insights,
  Lock,
  Settings,
  ExitToApp,
  PlayArrowRounded,
  PlayArrowOutlined,
  PlayCircleFilledOutlined,
  RecentActors,
} from "@mui/icons-material";
import { ThemeColors } from "../styles/light-colors";
import LogoName from '../icons/LogoWithNameWhiteRed.png'; // Import the logo image
import adamImage from "../images/default_profile_pic.png"; // path to the image file
import { Link, useNavigate } from "react-router-dom";
import LocalStorageManager from "../common/LocalStorageManager";
import Firebase from "../auth/Firebase";
import { Org } from "../auth/Authorization";
import { getVersionedUrl } from "../common/Util";
import { TestChimpSDK } from "testchimp-js";

const SITE_URL = process.env.REACT_APP_SITE_URL;

const TitleBar = ({ isOobePage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageTitle, setPageTitle] = useState("");
  const navigate = useNavigate();
  const isGuardsEnabled = false;
  const isUserSettingsEnabled = false;
  const isScenariosEnabled = true;

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    LocalStorageManager.clear();
    Firebase.signOut();
    TestChimpSDK.endTrackedSession();
    window.location.href = SITE_URL;
  };

  const styles = {
    appBar: {
      backgroundColor: ThemeColors.titleBackground,
      transition: "background-color 0.3s ease",
      zIndex: 2000, //Greater than overlay(default is 1200)
    },
    iconButton: {
      color: ThemeColors.white,
      fontFamily: "'Roboto Slab', sans-serif",
      marginLeft: "1em",
    },
    selectedIconButton: {
      color: "white",
      fontWeight: "bold",
    },
    menuPaper: {
      marginTop: "48px",
    },
    avatar: {
      marginLeft: "8px",
    },
  };

  let currentUser = Firebase.getCurrentUser();
  let profileImage = currentUser?.photoURL ?? adamImage;

  let content = <></>;
  if (isOobePage) {
    content = (
      <></>
    );
  } else {
    content = (
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to={getVersionedUrl("/sessions")}>
            <img
              src={LogoName}
              style={{
                height: "35px",
                padding: "10px",
              }}
            />
          </Link>
          <IconButton
            onClick={() => navigate(getVersionedUrl("/sessions"))}
            style={styles.iconButton}
            aria-label="sessions"
          >
            <PlayCircleFilledOutlined />
            <Typography
              variant="body1"
              style={{
                color: ThemeColors.white,
                fontWeight: "normal",
                marginLeft: "10px",
              }}
            >
              {" "}
              Sessions
            </Typography>
          </IconButton>
          <IconButton
            onClick={() => navigate(getVersionedUrl("/trackedtests"))}
            style={styles.iconButton}
            aria-label="trackedtests"
          >
            <Insights />
            <Typography
              variant="body1"
              style={{
                color: ThemeColors.white,
                fontWeight: "normal",
                marginLeft: "10px",
              }}
            >
              {" "}
              Tests
            </Typography>
          </IconButton>
          {isScenariosEnabled && (
            <IconButton
              onClick={() => navigate(getVersionedUrl("/epics"))}
              style={styles.iconButton}
              aria-label="sessions"
            >
              <RecentActors />
              <Typography
                variant="body1"
                style={{
                  color: ThemeColors.white,
                  fontWeight: "normal",
                  marginLeft: "10px",
                }}
              >
                {" "}
                Scenarios
              </Typography>
            </IconButton>)}
          <IconButton
            onClick={() => navigate(getVersionedUrl("/apis"))}
            style={styles.iconButton}
            aria-label="flows"
          >
            <AccountTree />
            <Typography
              variant="body1"
              style={{
                color: ThemeColors.white,
                fontWeight: "normal",
                marginLeft: "10px",
              }}
            >
              {" "}
              APIs
            </Typography>
          </IconButton>
          {isGuardsEnabled && <IconButton
            onClick={() =>
              navigate(getVersionedUrl("/guards"), {
                state: undefined,
                replace: true,
              })
            }
            style={styles.iconButton}
            aria-label="guards"
          >
            <Lock />
            <Typography
              variant="body1"
              style={{
                color: ThemeColors.white,
                fontWeight: "normal",
                marginLeft: "10px",
              }}
            >
              {" "}
              Guards
            </Typography>
          </IconButton>}
        </div>
        <Typography
          variant="body1"
          style={{
            marginLeft: "10px",
            fontWeight: "400",
            fontFamily: "'Roboto Slab', sans-serif",
          }}
        >
          {pageTitle}
        </Typography>
        <IconButton
          style={styles.avatar}
          aria-label="profile"
          onClick={handleMenuClick}
        >
          <Avatar style={{ backgroundColor: "gray" }} src={profileImage} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          classes={{ paper: styles.menuPaper }}
        >
          {Org.isAdmin() && (
            <MenuItem
              onClick={() => {
                handleMenuClose();
                navigate(getVersionedUrl("/admin-settings"));
              }}
            >
              <Settings color={ThemeColors.primary} /> Admin Settings
            </MenuItem>
          )}
          {isUserSettingsEnabled && <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate(getVersionedUrl("/user-settings"));
            }}
          >
            <Settings color={ThemeColors.primary} /> User Settings
          </MenuItem>}
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate(getVersionedUrl("/project-settings"));
            }}
          >
            <Settings color={ThemeColors.primary} /> Project Settings
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              handleLogOut();
            }}
          >
            <ExitToApp color={ThemeColors.primary} /> Log Out
          </MenuItem>
        </Menu>
      </Toolbar>
    );
  }
  return (
    <AppBar position="static" style={styles.appBar}>
      {content}
    </AppBar>
  );
};

export default TitleBar;
