import React, { useState, useEffect } from "react";
import { Select, Button, Table, Dropdown, Menu, message, Modal } from "antd";
import styled from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import { getProjects } from "../api/services/User";
import Scenarios, { Epic, UpsertEpicRequest, Persona, Milestone, Sprint, CreateSprintResponse, CreateMilestoneResponse } from "../api/services/Scenarios";
import LocalStorageManager from "../common/LocalStorageManager";
import { Paper, ThemeProvider, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import theme from "../common/theme";
import ManagePersonasDialog from "./ManagePersonasDialog";
import "../styles/EpicViewer.css";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setSelectedProject } from "../features/commonSlice";

const { Option } = Select;

const PlayIcon = styled(ArrowDropDownIcon)`
  &:hover {
    color: var(--secondary-cta-color);
  }
`;

const AddIconStyled = styled(AddIcon)`
  margin-right: 8px;
`;


const EpicViewer: React.FC = () => {
    const [projects, setProjects] = useState([]);
    const selectedProject = useAppSelector((state) => state.common.selectedProject);
    const [epics, setEpics] = useState<Epic[]>([]);
    const [personas, setPersonas] = useState<Persona[]>([]);
    const [sprints, setSprints] = useState<Sprint[]>([]);
    const [milestones, setMilestones] = useState<Milestone[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentEpic, setCurrentEpic] = useState<Epic | null>(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isProjectDescDialogOpen, setIsProjectDescDialogOpen] = useState(false);
    const [projectDescription, setProjectDescription] = useState('');
    const [isManagePersonasDialogOpen, setIsManagePersonasDialogOpen] = useState(false);
    const [isAddSprintDialogOpen, setIsAddSprintDialogOpen] = useState(false);
    const [isAddMilestoneDialogOpen, setIsAddMilestoneDialogOpen] = useState(false);
    const [sprintName, setSprintName] = useState('');
    const [sprintStartDate, setSprintStartDate] = useState(() => dayjs().add(1, "day").toISOString());
    const [sprintEndDate, setSprintEndDate] = useState(() => dayjs().add(14, "day").toISOString());
    const [milestoneStartDate, setMilestoneStartDate] = useState(() => dayjs().add(1, "day").toISOString());
    const [milestoneEndDate, setMilestoneEndDate] = useState(() => dayjs().add(14, "day").toISOString());

    const [milestoneName, setMilestoneName] = useState('');

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await getProjects.getProjects();
                setProjects(response.data.projects);
                if (response.data.projects?.length > 0) {
                    handleProjectChange(response.data.projects[0].id);
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };
        fetchProjects();
    }, []);

    const handleProjectChange = async (projectId: string) => {
        await dispatch(setSelectedProject(projectId));
        fetchEpics();
        fetchPersonas();
        fetchSprints();
        fetchMilestones();
        fetchProjectDescription();
    };

    const fetchEpics = async () => {
        setIsLoading(true);
        try {
            const response = await Scenarios.listEpics({});
            setEpics(response.epics);
        } catch (error) {
            console.error("Error fetching epics:", error);
        }
        setIsLoading(false);
    };

    const fetchPersonas = async () => {
        setIsLoading(true);
        try {
            const response = await Scenarios.listPersonas({});
            setPersonas(response.personas);
        } catch (error) {
            console.error("Error fetching personas:", error);
        }
        setIsLoading(false);
    };


    const fetchSprints = async () => {
        setIsLoading(true);
        try {
            const response = await Scenarios.listSprints({});
            setSprints(response.sprints);
        } catch (error) {
            console.error("Error fetching sprints:", error);
        }
        setIsLoading(false);
    };

    const fetchMilestones = async () => {
        setIsLoading(true);
        try {
            const response = await Scenarios.listMilestones({});
            setMilestones(response.milestones);
        } catch (error) {
            console.error("Error fetching milestones:", error);
        }
        setIsLoading(false);
    };

    const fetchProjectDescription = async () => {
        try {
            const response = await Scenarios.getProjectDescription({});
            setProjectDescription(response.description);
            console.log("Desc: ", response.description);
            if (!response.description) {
                handleOpenProjectDescDialog();
            }
        } catch (error) {
            console.error("Error fetching project description:", error);
        }
    };

    const handleViewUserStories = (epicId: number) => {
        if ((!personas || personas?.length == 0)) {
            Modal.info({
                title: 'Action Required',
                content: 'Please define personas before adding user stories.',
                onOk() { },
            });
        } else {
            window.open(`/user_stories?epic_id=${epicId}&project_id=${selectedProject}`, '_blank');
        }
    };

    const handleEditEpic = (epic: Epic) => {
        setCurrentEpic(epic);
        setTitle(epic.title);
        setDescription(epic.description + "");
        setIsEditing(true);
        setIsDialogOpen(true);
    };

    const handleAddEpic = () => {
        setCurrentEpic(null);
        setTitle('');
        setDescription('');
        setIsEditing(false);
        setIsDialogOpen(true);
    };

    const handleSave = async () => {
        if (isEditing && currentEpic) {
            try {
                let upsertRequest: UpsertEpicRequest = {
                    id: currentEpic.id,
                    externalId: currentEpic.externalId,
                    description: description,
                    title: title
                }
                await Scenarios.upsertEpic(upsertRequest);
                message.success('Epic updated successfully');
            } catch (error) {
                console.error("Error updating epic:", error);
                message.error('Failed to update epic');
            }
        } else {
            try {
                let createRequest: UpsertEpicRequest = {
                    description: description,
                    title: title
                }
                await Scenarios.upsertEpic(createRequest);
                message.success('Epic added successfully');
            } catch (error) {
                console.error("Error adding epic:", error);
                message.error('Failed to add epic');
            }
        }
        setIsDialogOpen(false);
        fetchEpics(); // Refresh epics
    };

    const handleCancel = () => {
        setIsDialogOpen(false);
    };

    const handleOpenProjectDescDialog = () => {
        setIsProjectDescDialogOpen(true);
    };

    const handleSaveProjectDescription = async () => {
        try {
            await Scenarios.updateProjectDescription({ description: projectDescription });
            message.success('Project description updated successfully');
            setIsProjectDescDialogOpen(false);
        } catch (error) {
            console.error("Error updating project description:", error);
            message.error('Failed to update project description');
        }
    };

    const handleCancelProjectDescription = () => {
        setIsProjectDescDialogOpen(false);
    };

    const handleOpenManagePersonasDialog = () => {
        setIsManagePersonasDialogOpen(true);
    };

    const handleSaveOfPersonas = async (updatedPersonas: Persona[]) => {
        try {
            await Scenarios.upsertPersonaList({ personas: updatedPersonas });
            setPersonas(updatedPersonas);
        } catch (error) {
            console.error("Error saving personas:", error);
            message.error('Failed to save personas');
        }
    };

    const handleOpenAddSprintDialog = () => {
        setSprintName('');
        setSprintStartDate(dayjs().add(1, "day").toISOString());
        setSprintEndDate(dayjs().add(14, "day").toISOString());
        setIsAddSprintDialogOpen(true);
    };

    const handleSaveSprint = async () => {
        if (sprintStartDate && sprintEndDate) {
            try {
                let response: CreateSprintResponse = await Scenarios.createSprint({
                    title: sprintName,
                    startTimestamp: sprintStartDate,
                    endTimestamp: sprintEndDate,
                });
                let newSprint: Sprint = {
                    id: response.id,
                    name: sprintName
                }
                setSprints(prevSprints => [...prevSprints ?? [], newSprint]);
                message.success('Sprint added successfully');
            } catch (error) {
                console.error("Error adding sprint:", error);
                message.error('Failed to add sprint');
            }
            setIsAddSprintDialogOpen(false);
            fetchEpics(); // Refresh epics
        } else {
            message.error('Please select start and end dates for the sprint');
        }
    };

    const handleCancelSprint = () => {
        setIsAddSprintDialogOpen(false);
    };

    const handleOpenAddMilestoneDialog = () => {
        setMilestoneName('');
        setMilestoneStartDate(dayjs().add(1, "day").toISOString());
        setMilestoneEndDate(dayjs().add(14, "day").toISOString());
        setIsAddMilestoneDialogOpen(true);
    };

    const handleSaveMilestone = async () => {
        if (milestoneStartDate && milestoneEndDate) {
            try {
                let response: CreateMilestoneResponse = await Scenarios.createMilestone({
                    title: milestoneName,
                    startTimestamp: milestoneStartDate,
                    endTimestamp: milestoneEndDate,
                });
                let newMilestone: Milestone = {
                    id: response.id,
                    name: milestoneName
                }
                setMilestones(prevMilestones => [...prevMilestones ?? [], newMilestone]);
                message.success('Milestone added successfully');
            } catch (error) {
                console.error("Error adding milestone:", error);
                message.error('Failed to add milestone');
            }
            setIsAddMilestoneDialogOpen(false);
            fetchEpics(); // Refresh epics
        } else {
            message.error('Please select start and end dates for the milestone');
        }
    };

    const handleCancelMilestone = () => {
        setIsAddMilestoneDialogOpen(false);
    };

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            width: 200,
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: 400,
            render: (text: string) => (text.length > 200 ? `${text.substring(0, 200)}...` : text),
        },
        {
            title: "Actions",
            key: "actions",
            width: 150,
            render: (text: string, record: Epic) => (
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item key="1" onClick={() => handleEditEpic(record)}>
                                Edit
                            </Menu.Item>
                            <Menu.Item key="2" onClick={() => handleViewUserStories(record.id)}>
                                View User Stories
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <Button icon={<PlayIcon />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <div className="top-container fade-in">
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div id="test-filter-panel">
                        <div className="flex-row">
                            <Select style={{ width: "100%" }}

                                value={selectedProject}
                                onChange={handleProjectChange}
                            >
                                {projects?.map((project: any) => (
                                    <Option key={project.id} value={project.id}>
                                        {project.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginTop: "10px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <Button className="primary-button" type="primary" onClick={handleOpenProjectDescDialog}>
                                    Set Project Description
                                </Button>
                                <Button className="primary-button" type="primary" onClick={handleOpenManagePersonasDialog}>
                                    Manage Personas
                                </Button>
                            </div>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <Button className="secondary-button" type="primary" onClick={handleOpenAddSprintDialog}>
                                    Add Sprint
                                </Button>
                                <Button className="secondary-button" type="primary" onClick={handleOpenAddMilestoneDialog}>
                                    Add Milestone
                                </Button>
                                <Button className="primary-button" type="primary" onClick={handleAddEpic}>
                                    Add New Epic...
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Paper id="table-container">

                        <Table
                            columns={columns}
                            dataSource={epics}
                            loading={isLoading}
                            rowKey="id"
                        />
                    </Paper>

                    {/* Add/Edit Epic Dialog */}
                    <Dialog fullWidth open={isDialogOpen} onClose={handleCancel}>
                        <DialogContent className="dialog-content">
                            <label className="dialog-title">{isEditing ? "Edit Epic" : "Add Epic"}</label>
                            <label className="dialog-subtitle">{"Epics help organize related user stories together"}</label>
                            <TextField
                                className="dialog-textfield"
                                autoFocus
                                margin="dense"
                                label="Title"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Title"
                            />
                            <TextField
                                className="dialog-textfield"
                                margin="dense"
                                label="Description"
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Description"
                            />
                        </DialogContent>
                        <DialogActions className="dialog-actions">
                            <Button onClick={handleSave} className="primary-button">
                                Save
                            </Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </DialogActions>
                    </Dialog>

                    {/* Add Sprint Dialog */}
                    <Dialog open={isAddSprintDialogOpen} onClose={handleCancelSprint}>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="sprintName"
                                label="Sprint Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={sprintName}
                                onChange={(e) => setSprintName(e.target.value)}
                            />
                            <div style={{ marginTop: '20px', display: 'flex', gap: '20px' }}>
                                <DatePicker
                                    label="Start Date:"
                                    value={dayjs(sprintStartDate)}
                                    onChange={(date) => setSprintStartDate(date!.toISOString())}
                                />
                                <DatePicker
                                    label="End Date:"
                                    value={dayjs(sprintEndDate)}
                                    onChange={(date) => setSprintEndDate(date!.toISOString())}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancelSprint} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleSaveSprint} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Add Milestone Dialog */}
                    <Dialog open={isAddMilestoneDialogOpen} onClose={handleCancelMilestone}>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="milestoneName"
                                label="Milestone Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={milestoneName}
                                onChange={(e) => setMilestoneName(e.target.value)}
                            />
                            <div style={{ marginTop: '20px', display: 'flex', gap: '20px' }}>
                                <DatePicker
                                    label="Start Date:"
                                    value={dayjs(milestoneStartDate)}
                                    onChange={(date) => setMilestoneStartDate(date!.toISOString())}
                                />
                                <DatePicker
                                    label="End Date:"
                                    value={dayjs(milestoneEndDate)}
                                    onChange={(date) => setMilestoneEndDate(date!.toISOString())}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancelMilestone} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleSaveMilestone} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Manage Personas Dialog */}
                    <ManagePersonasDialog
                        open={isManagePersonasDialogOpen}
                        onClose={() => setIsManagePersonasDialogOpen(false)}
                        projectId={selectedProject!}
                        handleSaveOfPersonas={handleSaveOfPersonas}
                    />

                    {/* Project Description Dialog */}
                    <Dialog fullWidth open={isProjectDescDialogOpen} onClose={handleCancelProjectDescription}>
                        <DialogContent className="dialog-content">
                            <label className="dialog-title">Explain in a few sentences what your project is about...</label>
                            <TextField
                                className="dialog-textfield"
                                autoFocus
                                margin="dense"
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                value={projectDescription}
                                onChange={(e) => setProjectDescription(e.target.value)}
                                placeholder="Project Description"
                            />
                            <label className="dialog-subtitle">This will be used to provide helpful suggestions on user stories / test scenarios.</label>
                        </DialogContent>
                        <DialogActions className="dialog-actions">
                            <Button onClick={handleSaveProjectDescription} className="primary-button">
                                Save
                            </Button>
                            <Button onClick={handleCancelProjectDescription}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </LocalizationProvider>
            </ThemeProvider>
        </div>
    );
};

export default EpicViewer;