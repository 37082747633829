import React, { useState } from 'react';
import { Tabs, Tab, Box, TextField, IconButton, Tooltip, Typography, Button } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import "../styles/GettingStarted.css";
import { ReactComponent as ChromeLogo } from '../images/chrome-logo.svg';
import beautify from "beautify";

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

const GettingStartedBox: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);

  const npmCommand = "npm install testchimp-js";
  const importStatement = `import { TestChimpSDK } from "testchimp-js";

  document.addEventListener("DOMContentLoaded", function () {
    TestChimpSDK.startRecording({
      // Conditionally enable for test env
      enableRecording: true,
      projectId: "<YOUR_TESTCHIMP_PROJECT_ID>",
      sessionRecordingApiKey: "<YOUR_TESTCHIMP_SESSION_RECORD_API_KEY>",
      // If backend sdks not added, put your domain urls here
      untracedUriRegexListToTrack: [".*://your-domain.*$"],
      // If backend sdks are added,put your domain urls here.
      tracedUriRegexListToTrack: ["/^$/"],
      environment: "QA"
  });
};`;
  const format = (body: string) => {
    return beautify(body, { format: "js" });
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className="getting-started-container fade-in">
      <div className="getting-started-content">
        <div className="getting-started-title">Quick Start Guide</div>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="With SDK" />
          <Tab label="With Chrome Extension" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="getting-started-text left-align">Step 1. Run: </div>
          <TextField
            value={npmCommand}
            InputProps={{
              readOnly: true,
              style: { fontFamily: 'monospace', fontWeight: 'bold', color: "green" },
              endAdornment: (
                <Tooltip title="Copy to clipboard">
                  <IconButton onClick={() => copyToClipboard(npmCommand)}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <div className="getting-started-text left-align">Step 2: Include the following in your index.js or equivalent</div>

          <TextField
            value={importStatement}
            InputProps={{
              readOnly: true,
              style: { fontFamily: 'monospace' },
              endAdornment: (
                <Tooltip title="Copy to clipboard">
                  <IconButton onClick={() => copyToClipboard(format(importStatement))}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={8} // Adjust the number of rows as needed
          />
          <div className="getting-started-text left-align">
            Step 3: Call <span className="monospace"><b>TestChimpSDK.setCurrentUserId("&lt;USER_ID&gt;")</b></span> to register a readable user id (eg: email) for the current session.
          </div>
          <div className="getting-started-text left-align">
            Step 4: Call <span className="monospace"><b>TestChimpSDK.endTrackedSession()</b></span> when the user signs out from your application.
          </div>
          <div className="getting-started-text left-align">
            <br />Thats it! Now TestChimpSDK will automatically capture sessions and help create automated tests.
          </div>
          <div className="getting-started-text left-align">
            <br />For a detailed guide including full stack recording capabilities and more configurations, <a href="https://github.com/awarelabshq/testchimp-sdk/blob/main/README.md#testchimp-sdk---enabling-full-stack-recording-for-test-creation" target='_blank'>refer here.</a>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="getting-started-text left-align">
            Install the chrome extesion:
            <Button
              variant="contained"
              startIcon={<ChromeLogo style={{ width: '24px', height: '24px' }} />}
              sx={{ marginLeft: "20px", backgroundColor: 'var(--secondary-cta-color)', color: 'var(--dark-color)' }}
              href="https://chromewebstore.google.com/detail/testchimp-create-api-auto/ailhophdeloancmhdklbbkobcbbnbglm" target='_blank'
            >
              Add to Chrome
            </Button>
          </div>
          <div className="getting-started-text left-align">
            <br />For a detailed guide on using the extension, <a href="https://testchimp.io/blog/capturing-sessions-with-testchimp-chrome-extension" target='_blank'>refer here.</a>
          </div>
          <div className="getting-started-text left-align">
            <i><br />While onboarding with our SDK is the recommended way since it:</i>
          </div>
          <div className="getting-started-text left-align">
            <i><ul><li>Enables always-on capture</li><li>Tight integration with the user session lifecycle</li><li>Required for full stack capture</li> </ul></i>
          </div>
          <div className="getting-started-text left-align">
            <i>Chrome extension is useful for setups where you don't have access to code under test (or simply want to get a quick feel of TestChimp). With the extension, you can capture sessions, which are automatically exported to your TestChimp workspace, where you can collaborate with team mates and build automation tests.</i>
          </div>
          <IconButton>
          </IconButton>
        </TabPanel>
      </div>        </div>

  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default GettingStartedBox;